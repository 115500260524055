import { BaseLayout } from "@/layouts/base-layout";
import { LoadingStatus } from "@llamaindex/component/ui/loading";

export type LoadingLayoutProps = {
  message: string;
};

export default function LoadingLayout({ message }: LoadingLayoutProps) {
  return (
    <BaseLayout>
      <div className="flex flex-1 flex-col items-center justify-center overflow-auto text-lg text-slate-500">
        <LoadingStatus message={message} />
      </div>
    </BaseLayout>
  );
}
