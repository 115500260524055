"use client";
import LoadingLayout from "@/components/section/loading-layout";
import { useProjects } from "@/hooks/project";
import { useAppStore } from "@/store";
import { Loading } from "@llamaindex/component/ui/loading";
import { usePathname } from "next/navigation";
import React, { Suspense, useEffect } from "react";

const ProjectDefenderInner: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const pathname = usePathname();
  const { data: projects } = useProjects();
  const currentProjectId = useAppStore((state) => state.projectId);
  const setCurrentProjectId = useAppStore((state) => state.setProjectId);

  useEffect(() => {
    const projectIdInPathname = pathname.split("/")[2] ?? null;
    const exist = projects.find(
      (project) => project.id === projectIdInPathname,
    );
    // always set the project id if it's in the pathname
    if (
      projectIdInPathname &&
      exist &&
      projectIdInPathname !== currentProjectId
    ) {
      setCurrentProjectId(projectIdInPathname);
    }
  }, [currentProjectId, pathname, projects, setCurrentProjectId]);

  useEffect(() => {
    if (useAppStore.persist.hasHydrated()) {
      if (!currentProjectId) {
        if (projects.length) {
          setCurrentProjectId(projects[0]!.id);
        }
      }
    } else {
      return useAppStore.persist.onFinishHydration(() => {
        if (!currentProjectId) {
          if (projects.length) {
            setCurrentProjectId(projects[0]!.id);
          }
        }
      });
    }
  }, [currentProjectId, projects, setCurrentProjectId]);

  if (!currentProjectId) {
    return <LoadingLayout message="Loading projects..." />;
  }
  return children;
};

/**
 * This component is responsible for ensuring that the current project id is set
 *
 * Only use this once in the app, at the top level of the app
 */
export const ProjectDefender: React.FC<React.PropsWithChildren> = (props) => {
  const pathname = usePathname();
  if (pathname.startsWith("/login")) {
    return <>{props.children}</>;
  }
  return (
    <Suspense fallback={<Loading />}>
      <ProjectDefenderInner {...props} />
    </Suspense>
  );
};
