'use client';
export async function createPolyfill() {
  if (typeof window === 'undefined') {
    return;
  }
  if (typeof window.ReadableStream !== 'function') {
    await import('web-streams-polyfill')
  } else if (typeof window.ReadableStream.prototype.values !== 'function') {
    // polyfill AsyncIterableIterator for ReadableStream, under Chrome 114.0.0 (2024-04-16)
    await import('web-streams-polyfill')
  }
}
