import {
  BaseLayoutClient,
  BaseLayoutClientProps,
} from "@/layouts/base-layout/client";
import { Header } from "@/layouts/base-layout/header";

export function BaseLayout(props: BaseLayoutClientProps) {
  return (
    <main className="flex h-screen max-h-screen min-h-screen flex-col overflow-hidden">
      <Header />
      <BaseLayoutClient className={props.className}>
        {props.children}
      </BaseLayoutClient>
    </main>
  );
}
