"use client";
import { useAuth } from "@/hooks/auth";
import { NAV_ITEM_TOOLTIP_DIRECTION } from "@/layouts/base-layout/navigation/main-section";
import { assertExists } from "@/lib/utils";
import { useAppStore } from "@/store";
import {
  createCheckoutSessionApiV1BillingCheckoutSessionPost,
  createCustomerPortalSessionApiV1BillingCustomerPortalSessionPost,
  usageApiV1ParsingUsageGet,
} from "@llamaindex/cloud";
import { Button } from "@llamaindex/component/ui/button";
import { ProgressBar } from "@llamaindex/component/ui/progress-bar";
import ToolTipper from "@llamaindex/component/ui/tooltipper";
import { useSuspenseQuery } from "@tanstack/react-query";
import { ArrowRight, CircleDollarSignIcon, Info } from "lucide-react";
import React from "react";
import { toast } from "sonner";

function useUsage() {
  return useSuspenseQuery({
    queryKey: ["usage"],
    queryFn: async () => {
      return usageApiV1ParsingUsageGet();
    },
  });
}

//#region todo: will be removed in pricing feature branch
export const LegacyInformationSection: React.FC = () => {
  const isCollapsed = useAppStore((state) => state.isCollapsed);
  const { user } = useAuth();
  const { data: userUsage } = useUsage();

  let redirectUrl = process.env.NEXT_PUBLIC_FRONTEND_URL;
  if (!redirectUrl && process.env.NEXT_PUBLIC_VERCEL_URL) {
    redirectUrl = process.env.NEXT_PUBLIC_VERCEL_URL;
    if (!redirectUrl.startsWith("https://")) {
      redirectUrl = `https://${redirectUrl}`;
    }
  }

  const redirectToCheckout = () => {
    assertExists(redirectUrl, "redirectUrl is undefined");
    createCheckoutSessionApiV1BillingCheckoutSessionPost({
      requestBody: {
        success_url: redirectUrl,
        cancel_url: redirectUrl,
      },
    })
      .then((url) => {
        window.location.href = url;
      })
      .catch(() => {
        toast.error("Failed to open checkout");
      });
  };

  const redirectToCustomerPortal = () => {
    assertExists(redirectUrl, "redirectUrl is undefined");
    createCustomerPortalSessionApiV1BillingCustomerPortalSessionPost({
      requestBody: {
        return_url: redirectUrl,
      },
    })
      .then((url) => {
        window.location.href = url;
      })
      .catch(() => {
        toast.error("Failed to open customer portal");
      });
  };

  if (isCollapsed) {
    return (
      <div className="flex flex-col items-center gap-2">
        <ToolTipper
          content={user?.parsePremium ? "Premium Plan" : "Free Plan"}
          side={NAV_ITEM_TOOLTIP_DIRECTION}
        >
          <CircleDollarSignIcon size={16} />
        </ToolTipper>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-1">
        <div className="mb-2 text-[11px] font-normal uppercase tracking-wide text-slate-400">
          Your plan
        </div>
        <div className="flex flex-col gap-2 text-xs">
          <div className="flex items-center gap-1.5 text-sm">
            <CircleDollarSignIcon size={16} />
            {user?.parsePremium ? "Premium Plan" : "Free Plan"}
          </div>
          {user?.parsePremium ? (
            <div className="ml-6 flex flex-col gap-2 text-xs">
              <div className="flex flex-col text-xs">
                {userUsage.usage_pdf_pages.toLocaleString()} Credits today
                <span className="text-2-75xs text-slate-400">
                  Unlimited usage
                </span>
              </div>
            </div>
          ) : (
            <>
              <ProgressBar
                max={userUsage.max_pdf_pages as number}
                value={userUsage.usage_pdf_pages}
                unit="pages per day"
              />
              <span className="text-xs text-slate-400">
                (1200 pages per file max)
              </span>
            </>
          )}
        </div>
        {!user?.parsePremium && (
          <ToolTipper
            delayDuration={25}
            content={
              <span>
                7,000 free credit per week. Then $0.003 <br /> per credit ($3
                per 1,000 credits).
                <br />
              </span>
            }
          >
            <div className="flex items-center justify-between gap-1.5">
              <div className="mt-2 grow">
                <Button
                  onClick={redirectToCheckout}
                  className="h-8 w-full text-xs"
                >
                  Upgrade Plan <ArrowRight className="ml-1 size-4" />
                </Button>
              </div>
              <div className="rounded-md p-1 hover:bg-gray-200">
                <Info className="size-4" />
              </div>
            </div>
          </ToolTipper>
        )}
        {user?.parsePremium && (
          <div
            className="cursor-pointer text-xs text-slate-400 underline transition duration-300 hover:text-slate-600"
            onClick={redirectToCustomerPortal}
          >
            Manage plan
          </div>
        )}
        {user?.parsingGrant !== undefined && (
          <div className="flex items-center text-xs">
            <span className="flex flex-col text-xs">
              <span>Grant</span>
              {user.parsingGrant} credits left
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
//#endregion todo: will be removed in pricing feature branch
